import '@css/app.css';
import '../css/typography.css';
import '../css/buttons.css';
import '../css/splide_override.css';
import htmx from 'htmx.org';


import 'lazysizes'

import LazyLoad from 'vanilla-lazyload';
var myLazyLoad = new LazyLoad({
  elements_selector: ".lazy",
});


import tableSort from "table-sort-js/table-sort.js";

htmx.on('htmx:afterSwap', function(event) {
  tableSort();
});

import Alpine from 'alpinejs'
window.Alpine = Alpine
Alpine.start()

import AOS from 'aos';
import 'aos/dist/aos.css';

//init after load
window.addEventListener('load', function () {



  AOS.init({
   once: true
  });
});


import 'fslightbox';

import Splide from '@splidejs/splide';
import '@splidejs/splide/css';


import { init } from "shareon";
import "shareon/css";
init();


// new Splide( '.splide' ).mount();
if (document.querySelector('.splide')) {
  new Splide('.splide', {
    type: 'slide',
    autoWidth: true,
    trimSpace: false,
    perMove: 1,
    lazyload: 'nearby',
    gap: '1rem',
    pagination: false,
  }).mount();
}

if (import.meta.hot) {
  import.meta.hot.accept(() => {
    // console.log("HMR");
  });
}


//Scroll to anchor smooth
document.querySelectorAll('a[href^="#"]').forEach(anchor => {
  anchor.addEventListener('click', function (e) {
    e.preventDefault();
    //Add to URL
    window.history.pushState({}, '', this.getAttribute('href'));
    document.querySelector(this.getAttribute('href')).scrollIntoView({
      behavior: 'smooth'
    });
  });
});



document.addEventListener('aos:in:big-numbers', () => {
	runAnimations();
  });
  
  // How long you want the animation to take, in ms
  const animationDuration = 2000;
  // Calculate how long each ‘frame’ should last if we want to update the animation 60 times per second
  const frameDuration = 1000 / 60;
  // Use that to calculate how many frames we need to complete the animation
  const totalFrames = Math.round( animationDuration / frameDuration );
  // An ease-out function that slows the count as it progresses
  const easeOutQuad = t => t * ( 2 - t );
  
  // The animation function, which takes an Element
  const animateCountUp = el => {
	  let frame = 0;
	  // If the innterHtml contains letters, don't count up
	  if ( !isNaN( parseInt( el.innerHTML, 10 ) )) {
	  const countTo = parseInt( el.innerHTML, 10 );
	  // Start the animation running 60 times per second
	  const counter = setInterval( () => {
		  frame++;
		  // Calculate our progress as a value between 0 and 1
		  // Pass that value to our easing function to get our
		  // progress on a curve
		  const progress = easeOutQuad( frame / totalFrames );
		  // Use the progress value to calculate the current count
		  const currentCount = Math.round( countTo * progress );
  
		  // If the current count has changed, update the element
		  if ( parseInt( el.innerHTML, 10 ) !== currentCount ) {
			  el.innerHTML = currentCount;
		  }
  
		  // If we’ve reached our last frame, stop the animation
		  if ( frame === totalFrames ) {
			  clearInterval( counter );
		  }
	  }, frameDuration );
  }
  };
  
  
  
  // Run the animation on all elements with a class of ‘countup’
  const runAnimations = () => {
	  const countupEls = document.querySelectorAll( '.countup' );
	  countupEls.forEach( animateCountUp );
  };
  runAnimations();
  